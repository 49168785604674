import '../styles/biography.scss'
import img from '../images/2.jpg'
import { useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import store from '../store/store';
import { observer } from 'mobx-react-lite';


const Biography = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Прокрутка до верхней части страницы при монтировании компонента
    }, []);
    
    const biographyRef = useRef()
    const creationRef = useRef()
    const civicActivityRef = useRef()

    var text = `Григорій Іванович ЛЮТИЙ  — український поет, член Національної спілки письменників України.

Народився 7 лютого 1949 року в місті Гуляйполі Запорізької області в сім'ї вчителів і хліборобів.
    
Батько Григорія Лютого теж писав вірші, які не знайшли іншого призначення, окрім потрапити до альбому його майбутньої дружини — Марії Явтухівни Пилипенко. Вечорами іноді слухали привезеного дідом Явтухом патефона. Ставили «Дивлюсь я на небо…», «І шумить, і гуде, дрібний дощик іде…» та ін. Потім мама брала гітару і співала під неї українські пісні.
    
Один дід Г. Лютого був репресований, і поет його ніколи не бачив. Але розповіді про його силу волі, рішучість, характер — у великій мірі формували свідомість митця. Рід по батьковій лінії мав свої традиції. Обов'язково одного з синів називали на честь діда… По маминій лінії дід Явтух і бабуся Мелашка, чиї предки, переселенці з Полтави, ввібрали в себе всі риси, пов'язані з цими іменами в українській літературі. Дід — майже все життя комірник, бабуся — тиха і богобоязна господиня.
    
Дитинство поета протікало на фоні мальовничої української природи, олюднений образ якої постійно присутній у його поезії. За городами жебоніла річечка Гайчур, де майбутній поет ловив рибу, пік раків з хлопцями, пас на її луках гусей. Після закінчення Сігорянської початкової школи з 1958 року навчався у Гуляйпільській середній школі № 1 і паралельно в музичній по класу баяна. Закінчивши школу, Григорій Лютий протягом року працював баяністом і завідувачем Любимівського Будинку культури. Потім вступив на філологічний факультет Запорізького педінституту.
    
Був одружений на Лютій Людмилі Іванівні, що працювала вчителем української мови та літератури у запорізькому колегіумі «Елінт» № 96.
    
Помер 9 квітня 2023 року у Запоріжжі.
    
Його вірші вперше були опубліковані в гуляйпільській районній газеті «Зоря комунізму» у 1964 році.
    
Іще в шкільні літа пощастило познайомитися з відомим поетом Василем Діденком, який на той час фактично мешкав у Києві, але постійно приїздив на Чапаївку до мами, привозячи на периферію атмосферу київських вітрів. Заборонені вірші М. Мамайсура, В. Симоненка, із яким в студентські літа товаришував В. Діденко, довгі розмови на чапаївських і зеленянських плесах запліднювали душу неповторним відчуттям причетності до долі народу. Приходило, що є «В кожного святе синівське право з матір'ю побуть на самоті…»
    
Становлення поетичного таланту Г. Лютого відбувалося і під впливом найкращих зразків світової літератури. Сам поет розповідав, що, будучи студентом, захоплювався творами Гете, Шіллера, Боккаччо, Сервантеса.
    
За станом здоров'я довелося перевестися на заочне відділення педінституту. Повернувся у Гуляйполе. Влаштувався у колгоспну багатотиражку.
    
У 1971 році поезія Г. Лютого була представлена українській аудиторії на сторінках газети «Літературна Україна». Вже тоді у добірці віршів прозвучав образ, який цитують і досьогодні: «На рушнику Дніпра, розшитого садами, несем твою Чернечу, як хлібину…» Брав участь у роботі ірпінських семінарів у Києві, куди його викликав голова кабінету по роботі з молодими при Спілці письменників України Дмитро Білоус особисто. Лютого помітили, відзначали зрілість таланту, оригінальність творчої манери, але книжку видати не могли.
    
Виростаючи в атмосфері шістдесятників, Г. Лютий передплатив до себе в Гуляйполе всі літературні журнали, заснував своє літературне об'єднання «Калинова сопілка». Це про нього поетеса Любов Геньба скаже: «Я закінчила двадцять років університету Лютого…» І з того ж об'єднання вийшли Олександр Михайлюта, Любов Геньба, Іван Доценко, Анатолій Горпинич.
    
Коли творчість Лютого одержала схвальну оцінку критика А. Макарова, він ніби подолав якийсь бар'єр, і це додало йому наснаги і творчої енергії.
    
У полеміці про Шевченківські настрої, роль поета, яку у 80-х роках вела «Літературна Україна», поетеса Неоніла Стефурак вловила ці настрої у першій збірці Лютого, написавши: «Є такий поет: „Стою, як тінь, мов тільки що із бою. Хіба б коня іще та справжню рану…“».
    
У 1982 році Г. Лютого викликали до Києва на творчий звіт, після якого його вірші протягом 2-3 років опублікували практично всі журнали: «Вітчизна», «Жовтень», «Дніпро», «Україна», «Донбас», «Київ». А 1984 року побачила світ перша книжка Г. Лютого «Крилатий корінь», за яку він став лауреатом обласної молодіжної премії ім. М. Андросова.
    
У другій книзі «Крона вічності» (1985) автор продовжує розвивати ту тему, яка є для нього близькою, — тему рідної землі, батьківщини. Він ніби кровно пов'язаний із народними джерелами, з яких і черпає силу для своєї поезії, осмислює нерозривну єдність людини і природи, яка є вічною.
    
В основі поезії Г. Лютого лежить саме любов, любов до рідного краю, до людей, до природи, нарешті до жінки, і саме це почуття є джерелом творчого натхнення. Гарні за духовною наснаженістю й емоційною силою, інтимні вірші Лютого сприймаються як молитовна сповідь не тільки ліричного героя, а й власне читача, бо йдеться у них про загальнолюдські, вічні цінності — кохання, молодість з її неповторними чарами і почуваннями. Його поезії показують це почуття багатогранним. Тут можна зустріти юнацьке чи дівоче романтичне кохання, його щирість і красу, драматизм нерозділеного почуття, любов до жінки-матері. Непогамований шал закоханого серця передається за допомогою довершених художніх знахідок, передусім метафор, персоніфікацій, порівнянь, символічних образів.
    
Одна за одною виходять книжки «Червона літера вогню» (1987), «Хліб любові» (1990), «Я воду пив з твого лиця» (1992), за яку Лютий був відзначений обласною літературною премією ім. В. Лісняка, «Гуляй-поле» (1996), дитяча книжка «Світлана» (1995), «Вибране» (1998), «Меди» (2000), а також у співавторстві зі співаком і композитором Анатолієм Сердюком — збірник пісень «Пісні Гуляйпільського краю» (1995), численні публікації в журналах, альманахах, газетах.
    
На вірші Григорія Лютого написано понад сотню пісень, які виконували Анатолій Сердюк, Лідія Михайленко, Ірина Ярчевська, Сергій Шестопалов, дует Шепелів та інші. Найбільш відомі з них: «Не питай», «Скрипка», «Вчительчин вальс», «Пісня Гуляйпільських весіль», «Солов'їний острог», «Хрестини Махна», «Блюз любові», «Шипшина».
    
Дбає про відродження духовності українського народу, української мови, культури, традицій, місце української літератури в літературному процесі сьогодення.
    
Його участь в роботі різних редколегій, виступи по радіо і телебаченню, зустрічі з трудовими колективами, студентами, школярами, інтелігенцією, поїздки у складі літературно-мистецьких делегацій, виховання літературної молоді, а з 1992 року він очолював обласне літоб'єднання ім. М. Гайдабури.
    
Учасник VIII Всесоюзної наради молодих письменників.
    
Був єдиним делегатом до Спілки письменників України на Всесвітньому фестивалі молоді і студентів у Москві в 1985 році.
    
1998—2016 рр. — голова Запорізької обласної організації НСПУ. 
    
З 2001 р. — редактор журналу «Хортиця».`

    var biography = `Народився 7 лютого 1949 року в місті Гуляйполі Запорізької області в сім'ї вчителів — хліборобів.

Батько Григорія Лютого теж писав вірші, які не знайшли іншого призначення, окрім потрапити до альбому його майбутньої дружини — Марії Явтухівни Пилипенко. Вечорами іноді слухали привезеного дідом Явтухом патефона. Ставили «Дивлюсь я на небо…», «І шумить, і гуде, дрібний дощик іде…» та ін. Потім мама брала гітару і співала під неї українські пісні.
    
Один дід Г. Лютого був репресований, і поет його ніколи не бачив. Але розповіді про його силу волі, рішучість, характер — у великій мірі формували свідомість митця. Рід по батьковій лінії мав свої традиції. Обов'язково одного з синів називали на честь діда… По маминій лінії дід Явтух і бабуся Мелашка, чиї предки, переселенці з Полтави, ввібрали в себе всі риси, пов'язані з цими іменами в українській літературі. Дід — майже все життя комірник, бабуся — тиха і богобоязна господиня.
    
Дитинство поета протікало на фоні мальовничої української природи, олюднений образ якої постійно присутній у його поезії. За городами жебоніла річечка Гайчур, де майбутній поет ловив рибу, пік раків з хлопцями, пас на її луках гусей. Після закінчення Сігорянської початкової школи з 1958 року навчався у Гуляйпільській середній школі № 1 і паралельно в музичній по класу баяна. Закінчивши школу, Григорій Лютий протягом року працював баяністом і завідувачем Любимівського Будинку культури. Потім вступив на філологічний факультет Запорізького педінституту.
    
Був одружений на Лютій Людмилі Іванівні, що працювала вчителем української мови та літератури у запорізькому колегіумі «Елінт» № 96.
    
Помер 9 квітня 2023 року у Запоріжжі[1]. `

    var creation = `Його вірші вперше були опубліковані в гуляйпільській районній газеті «Зоря комунізму» у 1964 році.

Іще в шкільні літа пощастило познайомитися з відомим поетом Василем Діденком, який на той час фактично мешкав у Києві, але постійно приїздив на Чапаївку до мами, привозячи на периферію атмосферу київських вітрів. Заборонені вірші М. Мамайсура, В. Симоненка, із яким в студентські літа товаришував В. Діденко, довгі розмови на чапаївських і зеленянських плесах запліднювали душу неповторним відчуттям причетності до долі народу. Приходило, що є «В кожного святе синівське право з матір'ю побуть на самоті…»
    
Становлення поетичного таланту Г. Лютого відбувалося і під впливом найкращих зразків світової літератури. Сам поет розповідав, що, будучи студентом, захоплювався творами Гете, Шіллера, Боккаччо, Сервантеса.
    
За станом здоров'я довелося перевестися на заочне відділення педінституту. Повернувся у Гуляйполе. Влаштувався у колгоспну багатотиражку.
    
У 1971 році поезія Г. Лютого була представлена українській аудиторії на сторінках газети «Літературна Україна». Вже тоді у добірці віршів прозвучав образ, який цитують і досьогодні: «На рушнику Дніпра, розшитого садами, несем твою Чернечу, як хлібину…» Брав участь у роботі ірпінських семінарів у Києві, куди його викликав голова кабінету по роботі з молодими при Спілці письменників України Дмитро Білоус особисто. Лютого помітили, відзначали зрілість таланту, оригінальність творчої манери, але книжку видати не могли.
    
Виростаючи в атмосфері шістдесятників, Г. Лютий передплатив до себе в Гуляйполе всі літературні журнали, заснував своє літературне об'єднання «Калинова сопілка». Це про нього поетеса Любов Геньба скаже: «Я закінчила двадцять років університету Лютого…» І з того ж об'єднання вийшли Олександр Михайлюта, Любов Геньба, Іван Доценко, Анатолій Горпинич.
    
Коли творчість Лютого одержала схвальну оцінку критика А. Макарова, він ніби подолав якийсь бар'єр, і це додало йому наснаги і творчої енергії.
    
У полеміці про Шевченківські настрої, роль поета, яку у 80-х роках вела «Літературна Україна», поетеса Неоніла Стефурак вловила ці настрої у першій збірці Лютого, написавши: «Є такий поет: „Стою, як тінь, мов тільки що із бою. Хіба б коня іще та справжню рану…“».
    
У 1982 році Г. Лютого викликали до Києва на творчий звіт, після якого його вірші протягом 2-3 років опублікували практично всі журнали: «Вітчизна», «Жовтень», «Дніпро», «Україна», «Донбас», «Київ». А 1984 року побачила світ перша книжка Г. Лютого «Крилатий корінь», за яку він став лауреатом обласної молодіжної премії ім. М. Андросова.
    
У другій книзі «Крона вічності» (1985) автор продовжує розвивати ту тему, яка є для нього близькою, — тему рідної землі, батьківщини. Він ніби кровно пов'язаний із народними джерелами, з яких і черпає силу для своєї поезії, осмислює нерозривну єдність людини і природи, яка є вічною.
    
В основі поезії Г. Лютого лежить саме любов, любов до рідного краю, до людей, до природи, нарешті до жінки, і саме це почуття є джерелом творчого натхнення. Гарні за духовною наснаженістю й емоційною силою, інтимні вірші Лютого сприймаються як молитовна сповідь не тільки ліричного героя, а й власне читача, бо йдеться у них про загальнолюдські, вічні цінності — кохання, молодість з її неповторними чарами і почуваннями. Його поезії показують це почуття багатогранним. Тут можна зустріти юнацьке чи дівоче романтичне кохання, його щирість і красу, драматизм нерозділеного почуття, любов до жінки-матері. Непогамований шал закоханого серця передається за допомогою довершених художніх знахідок, передусім метафор, персоніфікацій, порівнянь, символічних образів.
    
Одна за одною виходять книжки «Червона літера вогню» (1987), «Хліб любові» (1990), «Я воду пив з твого лиця» (1992), за яку Лютий був відзначений обласною літературною премією ім. В. Лісняка, «Гуляй-поле» (1996), дитяча книжка «Світлана» (1995), «Вибране» (1998), «Меди» (2000), а також у співавторстві зі співаком і композитором Анатолієм Сердюком — збірник пісень «Пісні Гуляйпільського краю» (1995), численні публікації в журналах, альманахах, газетах.
    
На вірші Григорія Лютого написано понад сотню пісень, які виконували Анатолій Сердюк, Лідія Михайленко, Ірина Ярчевська, Сергій Шестопалов, дует Шепелів та інші. Найбільш відомі з них: «Не питай», «Скрипка», «Вчительчин вальс», «Пісня Гуляйпільських весіль», «Солов'їний острог», «Хрестини Махна», «Блюз любові», «Шипшина». `

    var civic_activity = `Дбає про відродження духовності українського народу, української мови, культури, традицій, місце української літератури в літературному процесі сьогодення.

Його участь в роботі різних редколегій, виступи по радіо і телебаченню, зустрічі з трудовими колективами, студентами, школярами, інтелігенцією, поїздки у складі літературно-мистецьких делегацій, виховання літературної молоді, а з 1992 року він очолював обласне літоб'єднання ім. М. Гайдабури.
    
Учасник VIII Всесоюзної наради молодих письменників.
    
Був єдиним делегатом до Спілки письменників України на Всесвітньому фестивалі молоді і студентів у Москві в 1985 році.
    
1998—2016 рр. — голова Запорізької обласної організації НСПУ[2].
    
З 2001 р. — редактор журналу «Хортиця». `


    return (
        <div className="biography">
            <div className='biography__content'>
                <h1>Григорій Лютий</h1>
                <br/>

                <div className='contents'>
                    <h3>Зміст</h3>
                    <h4 onClick={() => {biographyRef.current.scrollIntoView({block: 'center', behavior: 'smooth' });}}>Біографічні відомості</h4>
                    <h4 onClick={() => {creationRef.current.scrollIntoView({block: 'center', behavior: 'smooth' });}}>Творчість</h4>
                    <h4 onClick={() => {civicActivityRef.current.scrollIntoView({block: 'center', behavior: 'smooth' });}}>Громадянська діяльність</h4>
                </div>
                {store.isMobile && <div className='biography__absolute-block'>
                <img src={img}/>
                <ul className='table'>
                    <li>
                        <div className='table__title'>Народився:</div>
                        <div className='table__subtitle'>7 лютого 1949 Гуляйполе, Запорізька область, УРСР</div>
                        
                    </li>
                    <li>
                        <div className='table__title'>Помер:</div>
                        <div className='table__subtitle'>9 квітня 2023 (74 роки) Запоріжжя, Україна</div> 
                    </li>
                    <li>
                        <div className='table__title'>У шлюбі з:</div>
                        <div className='table__subtitle'>Люта Людмила Іванівна</div> 
                    </li>
                </ul>
                </div>}
                {store.isMobile && <br/>}
                <h2 ref={biographyRef}>Біографія</h2>
                <p>{biography}</p>
                <h2 ref={creationRef}>Творчість</h2>
                <p>{creation}</p>
                <h2 ref={civicActivityRef}>Громадянська активність</h2>
                <p>{civic_activity}</p>
            </div>
            {!store.isMobile && <div className='biography__absolute-block'>
                <img src={img}/>
                <ul className='table'>
                    <li>
                        <div className='table__title'>Народився:</div>
                        <div className='table__subtitle'>7 лютого 1949 Гуляйполе, Запорізька область, УРСР</div>
                        
                    </li>
                    <li>
                        <div className='table__title'>Помер:</div>
                        <div className='table__subtitle'>9 квітня 2023 (74 роки) Запоріжжя, Україна</div> 
                    </li>
                    <li>
                        <div className='table__title'>У шлюбі з:</div>
                        <div className='table__subtitle'>Люта Людмила Іванівна</div> 
                    </li>
                </ul>
            </div>}
        </div>
    );
};

export default observer(Biography);