import Item from '../../components/Item';
import poems from './file.json'
import allPoems from './all-poems.json'
import '../../styles/poems.scss'
import { useEffect, useState } from 'react';
import seach_img from '../../images/search.png'
import TextItem from '../../components/TextItem';

const itemsPerPage = 26; 

const Poems = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Прокрутка до верхней части страницы при монтировании компонента
    }, []);
    
    const [selectedValue, setSelectedValue] = useState('decrease');
    const [currentPage, setCurrentPage] = useState(1); // текущая страница
    const [filteredData, setFilteredData] = useState([]); // отфильтрованные данные
  
    // Получаем индексы элементов для текущей страницы
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    // Изменяем страницу
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleSearch = (event) => {
        const name = event.target.value;
        // setSearchName(name);
        const filtered = allPoems.filter(poem => poem.name.toLowerCase().includes(name.toLowerCase()));
        setFilteredData(filtered);
    };

    useEffect(() => {
        if(selectedValue == "dec"){
            setFilteredData(allPoems.sort((a, b) => a.year - b.year))
        }else{
            setFilteredData(allPoems.sort((a, b) => b.year - a.year ))
        }
    }, [allPoems, selectedValue])

    return (
        <div className='poems'>
            <h1 className='title underlined marck'>Вірші Григорія Лютого</h1>
            <div className='items-container'>
                {poems.map(item => (
                    <Item name={item.name} id={item.id}/>
                ))}
            </div>

            <div className='poems__filters'>
                <div className='poems__filter'>
                    сортувати за 
                    <select name={selectedValue} onChange={handleChange}>
                        <option value="decrease">роками по спаданню</option>
                        <option value="increase">роками по зростанню</option>
                    </select>
                </div>

                <div className='poems__search'>
                    <img src={seach_img}/>
                    <input placeholder='пошук' onChange={handleSearch}/>
                </div>
            </div>
        
            <div className="items-container">
                {currentItems.map((item) => (
                <TextItem name={item.name} id={item.id} text={item.text} />
            ))}
            </div>

            <div className="pagination">
                {Array.from(Array(Math.ceil(filteredData.length / itemsPerPage)), (_, index) => (
                    <button key={index} disabled={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                        {index + 1}
                    </button>
                ))}
            </div>
        
        </div>
    );
};

export default Poems;