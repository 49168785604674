import { observable, action, makeObservable } from 'mobx';
import data from '../pages/poems/all-poems.json'

class CounterStore {
    poems = [];
    firstSixPoems = [];
    isMobile = false
    
    constructor() {
        makeObservable(this, {
            isMobile: observable,
            poems: observable,
            firstSixPoems: observable,
            setIsMobile: action
        });
    }

    setIsMobile(v){
        this.isMobile = v
    }

    async loadPoems() {
        try {
            this.poems = data;
            this.firstSixPoems = data.slice(0, 6); // Создание нового массива с первыми 6 элементами
        } catch (error) {
            console.error('Error loading poems:', error);
        }
    }
}

export default new CounterStore();