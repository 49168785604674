import React, { useState, useEffect } from 'react';
import '../styles/text-item.scss'
import { useNavigate } from 'react-router-dom';

// Create a context for the images directory
const imagesContext = require.context('../images', true, /\.(png|jpe?g|svg)$/);

const TextItem = ({ name, id, text }) => {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const importImage = async () => {
      try {
        // Dynamically import the image using the context
        const imageModule = await import(`../images/item${id}.jpg`);
        setImgSrc(imageModule.default);
      } catch (err) {
        console.error(`Error importing image: ${err}`);
      }
    };

    importImage();
  }, [id]);

  const navigate = useNavigate()
  return (
    <div className="text-item" onClick={() => navigate(`/poem/${id}`)}>
        <h2>{name}</h2>
        <pre>{text}</pre>
    </div>
  );
};

export default TextItem;