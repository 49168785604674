import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import '../styles/poem.scss'
import ItemsContainer from "./ItemsContainer";
import store from "../store/store";
import {observer} from 'mobx-react-lite'
import Footer from "./Footer";

const imagesContext = require.context('../images', true, /\.(png|jpe?g|svg)$/);

const Poem = () => {
    const {id} = useParams()
    const [poem, setPoem] = useState({})
    const [randomPoems, setRandomPoems] = useState([])
    const [poems, setPoems] = useState([])

    useEffect(() => {
        setPoems(store.poems)
    }, [store.poems])

    useEffect(() => {
      window.scrollTo(0, 0);
        if(poems.length > 0){
            setPoem(poems.find(item => item.id === parseInt(id, 10)))
            if(poem) setRandomPoems(getRandomObjects(poems, 3))
        }
    }, [poems, id, poem])


    const [imgSrc, setImgSrc] = useState(null);

    useEffect(() => {
      const importImage = async () => {
        try {
          // Dynamically import the image using the context
          const imageModule = await import(`../images/item${id}.jpg`);
          setImgSrc(imageModule.default);
        } catch (err) {
          console.error(`Error importing image: ${err}`);
        }
      };
  
      importImage();
    }, [id]);

   


    function getRandomObjects(array, count) {
        // Create a new array without the current poem object
        const shuffledArray = array.filter(obj => obj.name !== poem.name);
      
        var i = shuffledArray.length, min = i - count, temp, index;
      
        // Shuffle the array
        while (i-- > min) {
          index = Math.floor((i + 1) * Math.random());
          temp = shuffledArray[index];
          shuffledArray[index] = shuffledArray[i];
          shuffledArray[i] = temp;
        }
      
        // Return the desired number of random objects
        return shuffledArray.slice(min);
      }
    
    return (
        <div className="poem" >
            <div className="poem__top-block">
                <h1>{poem && poem.name} - Григорій Лютий</h1>
            </div>
            <div className="poem__content">
                {window.innerWidth >= 768 && <div>
                    
                    <pre>{poem && poem.text} </pre>
                </div>}
                
                <div className="jj">
                    <img src={imgSrc}/>
                    <p>Рік видання: {poem && poem.year}</p>
                    <p>Збірка: {poem && poem.collection}</p>
                </div>
                {window.innerWidth <= 768 && <div>
                    
                    <pre>{poem && poem.text} </pre>
                </div>}
            </div>
            <h1 style={{ fontSize: '32px'}} className="title underlined">Інші вірші</h1>
               
            <ItemsContainer items={randomPoems}/>
            {store.count}
          
        </div>
    );
};

export default observer(Poem);