import React, { useState, useEffect, useRef } from 'react';
import playButton from '../images/play.png'
import pauseButton from '../images/pause.png'
import '../styles/audio-player.scss'

const AudioPlayer = ({}) => {
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [active, setActive] = useState(false);
  const [isActivated, setIsActivated] = useState(false)
  const [isRewind, setIsRewind] = useState(false)

  const audio_player = useRef(null);

  useEffect(() => {
    if (!isActivated) return 
    const newAudio = new Audio();
    newAudio.crossOrigin = 'anonymous'
    newAudio.src = 'https://theromadevua.online/videoplayback.m4a';
    
    newAudio.volume = 0.1;
    newAudio.onloadedmetadata = () => {
      setDuration(Math.ceil(newAudio.duration));
    };
    newAudio.ontimeupdate = () => {
      setCurrentTime(Math.ceil(newAudio.currentTime));
    };
    newAudio.addEventListener('ended', () => {
      newAudio.currentTime = 0
      setIsPlaying(false)
      setActive(false)
    });
    setAudio(newAudio);

    return () => {
      // Остановка и очистка объекта audio при размонтировании компонента
      newAudio.pause();
      newAudio.src = '';
      setAudio(null);
    };
  }, [isActivated]);
 
  useEffect(() => {
    if(isActivated == true){
      setTimeout(() => {
        play()
      }, 100);
    }
  }, [audio])

  const [isPlayed, setIsPlayed] = useState(false)

  const play = () => {
    if (audio) {
      if(isPlayed == false){
        setIsPlayed(true)
        visualize()
        audio.play()
        setIsPlaying(true);
        setActive(true);
      }else{
        console.log('sddssd')
        audio.play()
        setIsPlaying(true);
        setActive(true);
       
      }

    }
  };

  const pause = () => {
    if (audio) {
      audio.pause();
      src = null
      
      setIsPlaying(false);
      setActive(false);
    }
  };


  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    if (hours > 0) {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  function parseTimeToSeconds(timeString) {
    const timeComponents = timeString.split(':').map(component => parseInt(component, 10));
    let totalSeconds = 0;
    console.log(timeComponents)
    if (timeComponents.length === 3) {
      totalSeconds += timeComponents[0] * 3600; // часы в секундах
      totalSeconds += timeComponents[1] * 60; // минуты в секундах
      totalSeconds += timeComponents[2]; // секунды
    } else if (timeComponents.length === 2) {
      totalSeconds += timeComponents[0] * 60; // минуты в секундах
      totalSeconds += timeComponents[1]; // секунды
    } else {
      throw new Error('Неправильный формат времени');
    }
  
    return totalSeconds;
  }


  var src, num, array, width, context, logo, myElements, analyser, src, height, spots;
  
  num = 42;

  array = new Uint8Array(num*2);

  

  useEffect(() => {
    if (!isActivated) return 
      let spot = audio_player.current.querySelector('.spot')

      for(var i = 0 ; i < num ; i++){
        
        logo = document.createElement('div');
        logo.setAttribute('class', 'logo');
    
        spot.appendChild(logo);
      }
  }, [isActivated])

  useEffect(() => {
    if (!isActivated) return 
    if(audio){
      var block = audio_player.current.querySelector('.spot');
      var variableValue = 0;
      var isMouseDown = false;
  
      block.addEventListener('mousedown', function(event) {
        var blockRect = block.getBoundingClientRect();
          var cursorX = event.clientX - blockRect.left;
          var blockWidth = blockRect.width;

          variableValue = Math.round((cursorX / blockWidth) * 100);
          
          console.log('Значение переменной:', variableValue);
        isMouseDown = true;
      });
  
      block.addEventListener('mousemove', function(event) {
        if (isMouseDown) {
          var blockRect = block.getBoundingClientRect();
          var cursorX = event.clientX - blockRect.left;
          var blockWidth = blockRect.width;

          variableValue = Math.round((cursorX / blockWidth) * 100);
          if(audio) {
            audio.currentTime = (( variableValue / 100) * duration).toFixed(2);
           
          }
          console.log('Значение переменной:', variableValue);
        }
      });
  
      block.addEventListener('mouseup', function(event) {
        var blockRect = block.getBoundingClientRect();
        var cursorX = event.clientX - blockRect.left;
        var blockWidth = blockRect.width;
  
        variableValue = Math.round((cursorX / blockWidth) * 100);
        if(audio) {
          audio.currentTime = (( variableValue / 100) * duration).toFixed(2);
        }
        
        console.log('Значение переменной:', variableValue);
      isMouseDown = false;
      });
    }
    
  }, [audio, duration, isActivated])  

  const visualize = () => {
    if (context) {
      if (src) {
        src.disconnect();
        src = null;
      }
      context.close().then(() => {
        context = null;
      });
    }
    console.log(context, src)
    myElements = audio_player.current.querySelectorAll('.logo');
    context = new AudioContext();
    analyser = context.createAnalyser();
    if(!src){
      src = context.createMediaElementSource(audio);
    }
    src.connect(analyser);
    analyser.connect(context.destination)
    loop();
  }


  function loop() {
    window.requestAnimationFrame(loop);
    analyser.getByteFrequencyData(array);
    
    for(var i = 0 ; i < num ; i++){
        height = array[i+num];
        myElements[i].style.height = (height / 5)+'px';   
    }
}

useEffect(() => {
    if (!isActivated) return 
    let spot = audio_player.current.querySelector('.spot')
    myElements = spot.querySelectorAll('.logo');
    if(myElements){
      for(var i = 0 ; i < num ; i++){
       
        if((currentTime / duration) * 100 > ((i / num) * 100)){
          myElements[i].style.background = 'blue'
        }else{
          myElements[i].style.background = 'gray'
        }
    }
  
  }


  
}, [currentTime, isActivated])

  const [currentFocusedElement, setCurrentFocusedElement] = useState(null)
  useEffect(() => {
    if(!isRewind) return

    const input1 = document.getElementById("time-input-1")
    const input2 = document.getElementById("time-input-2")
    const input3 = document.getElementById("time-input-3")

    const handleFocus = (e) => {
     
      input1.setAttribute("placeholder", "00");
      input1.style.width = '20px';
      input2.setAttribute("placeholder", "00");
      input2.style.width = '20px';
      input3.setAttribute("placeholder", "00");
      input3.style.width = '20px';

      setCurrentFocusedElement(e.target);
    };


    input1.addEventListener("focus", handleFocus);
    input2.addEventListener("focus", handleFocus);
    input3.addEventListener("focus", handleFocus);

  }, [isRewind])

  useEffect(() => {
    if(!currentFocusedElement) return

    currentFocusedElement.setAttribute("placeholder", currentFocusedElement.id === "time-input-1" ? "години" : currentFocusedElement.id === "time-input-2" ? "хвилини" : "секунди");
    currentFocusedElement.style.width = '80px';
  }, [currentFocusedElement])

  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const setTime = () => {
    let sec = parseTimeToSeconds(`${hours}:${minutes}:${seconds}`)
    console.log(`${hours}:${minutes}:${seconds}`, sec)
    if (!isNaN(sec) && isFinite(sec) && sec > 0 && sec < duration) {
      audio.currentTime = sec;
    } else {
      console.error('Некорректное значение времени');
    }

    setHours(0)
    setMinutes(0)
    setSeconds(0)

    setIsRewind(false)
  }


  return (
    <div className='audio_player' ref={audio_player}>

        {isActivated ? 

        <>

        <div className='player_content'>
            {active ? (<div className='player_button' onClick={pause}><img  src={pauseButton}/></div>) : (<div className='player_button' onClick={play} ><img  src={playButton}/></div>)}
            <div className='spot' style={{display: 'flex'}}></div> 
        </div> 
        {!isRewind && <div className='time-management-block' onClick={() => setIsRewind(true)}>
          <p className='format-time'>
            {formatTime(currentTime)} / {formatTime(duration)}
          </p>
          перемотати
        </div> }   
       
        {isRewind && <div className='rewind'>
          <input placeholder='00' id="time-input-1" onChange={(e) => setHours(e.target.value)}/>:<input placeholder='00' id="time-input-2" onChange={(e) => setMinutes(e.target.value)}/>:<input placeholder='00' id="time-input-3" onChange={(e) => setSeconds(e.target.value)}/> <button onClick={setTime}>Змінити </button>
        </div>}
        
        </>
        
        
        
        : <div className='player_content'><div className='player_button' onClick={() => {setIsActivated(true)}}><img  src={playButton}/></div><p>Слухати у аудіоверсії</p></div>}
    </div>
  );
};

export default AudioPlayer;