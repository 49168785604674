
const Footer = () => {
    console.log(window.innerWidth - 40 - 20)
    return (
        <div className="footer">
            <h1>Комментарі</h1>
            <h3>(Щоб залишити комментар треба увійти з свого facebook аккаунту)</h3>
            <div className="footer__content">
              

                <div class="fb-comments" data-href="https://developers.facebook.com/docs/plugins/comments#configurator" data-width={window.innerWidth >= 1024 ? "1024" : `${window.innerWidth - 40 - 20}`} data-numposts="5"></div>
                
            </div>
        </div>
    );
};
export default Footer;