import '../styles/main.scss'

import ItemsContainer from '../components/ItemsContainer';
import { useEffect, useState } from 'react';

import img from '../images/4.jpg'
import store from '../store/store';

import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Прокрутка до верхней части страницы при монтировании компонента
    }, []);
    
    const navigate = useNavigate();
    useEffect(() => {
        

        function parallaxScroll(){
            var scrolled = window.scrollY;
            // var parallax = document.querySelector('.background');

           

            // isMobile && ( parallax.style.backgroundPositionY = -(scrolled * (isMobile ? -0.25 : 0)) -  (isMobile ? 0 : 0) + 'px') // Adjust the factor to control the parallax speed

            // var parallax = document.querySelector('.fourth-section__left-block');
            // parallax.style.backgroundPositionY = parallax.getBoundingClientRect().top * (isMobile ? 0.8 : 0.7) + 'px';
        }

        window.addEventListener('scroll', parallaxScroll);

        return () => {
            // Cleanup function to remove event listener
            window.removeEventListener('scroll', parallaxScroll);
        };
    }, [])
    
   


    let text1 = `Григорій Іванович ЛЮТИЙ  — український поет, член Національної спілки письменників України.

Народився 7 лютого 1949 року в місті Гуляйполі Запорізької області в сім'ї вчителів і хліборобів.
    
Батько Григорія Лютого теж писав вірші, які не знайшли іншого призначення, окрім потрапити до альбому його майбутньої дружини — Марії Явтухівни Пилипенко. Вечорами іноді слухали привезеного дідом Явтухом патефона. Ставили «Дивлюсь я на небо…», «І шумить, і гуде, дрібний дощик іде…» та ін. Потім мама брала гітару і співала під неї українські пісні.
    
Один дід Г. Лютого був репресований, і поет його ніколи не бачив. Але розповіді про його силу волі, рішучість, характер — у великій мірі формували свідомість митця. Рід по батьковій лінії мав свої традиції. Обов'язково одного з синів називали на честь діда… По маминій лінії дід Явтух і бабуся Мелашка, чиї предки, переселенці з Полтави, ввібрали в себе всі риси, пов'язані з цими іменами в українській літературі. Дід — майже все життя комірник, бабуся — тиха і богобоязна господиня.
    
Дитинство поета протікало на фоні мальовничої української природи, олюднений образ якої постійно присутній у його поезії. За городами жебоніла річечка Гайчур, де майбутній поет ловив рибу, пік раків з хлопцями, пас на її луках гусей. Після закінчення Сігорянської початкової школи з 1958 року навчався у Гуляйпільській середній школі № 1 і паралельно в музичній по класу баяна. Закінчивши школу, Григорій Лютий протягом року працював баяністом і завідувачем Любимівського Будинку культури. Потім вступив на філологічний факультет Запорізького педінституту.

Помер 9 квітня 2023 року у Запоріжжі.
...`


    return (
        <div className='main'>
            <div className='main-content'>
                <section className='first-section'>
                
                    <div className="background"></div>
                     <div className='first-section__info'>
                     
                        <div className='first-section__info-left-block'>
                            {!store.isMobile ?
                            <h1> Мій хліб — любов. Однині й назавжди.<br/>
                            Нехай мене любов моя годує.</h1>
                            : <><h1> Григорій Лютий.</h1><h3>Запорізький поет та письменник.</h3></>}
                            <button onClick={() => {navigate('/poems')}}>Читати вірші</button>
                        </div>
                        <div className='first-section__info-right-block'>
                            <img src={img} alt="Григорій Лютий"/>
                        </div>
                    </div>
                </section>

                <div className='third-section'>
                    <h1 className='title underlined marck third-section__title'>Кращі вірші</h1>
                    <div className='poems_conatainer'>
                        <ItemsContainer items={store.firstSixPoems}/>
                    </div>
                    <button className='third-section__button' onClick={() => {navigate('/poems')}}>Усі вірші</button>
                </div>

                <div className='fifth-section'>
                <h1 className=''>Григорій Лютий</h1>
                <h3>
                (7 лютого 1949 — 9 квітня 2023)
                </h3>
                <br/>
                <p> 
                Григорій Іванович Лютий - талановитий український письменник Запорізького краю, який збагатив українську літературу щирими і емоційними віршами. Його творчість відзначається глибоким патріотизмом, любов'ю до рідного краю і народу, а також високою художньою майстерністю. Автор роману "Мама Марія" - першого роману у віршах на Запоріжжі.
                {/* <br/>    <br/> */}
                <h4>Здобутки:</h4>
             
                <ul>
                    <li>Автор понад 20 збірок віршів, зокрема «Крилатий корінь» (1984), «Крона вічності» (1985), «Червона літера вогню» (1987), «Хліб любові» (1990), «Я воду пив з твого лиця» (1992), «Гуляй-поле» (1996), «Вибране» (1998), «Меди» (2000).</li>
                    <li>Лауреат обласної молодіжної премії ім. М. Андросова (1984), обласної літературної премії ім. В. Лісняка (1992).</li>
                    <li>На його вірші написано понад 100 пісень, які виконують відомі українські співаки.</li>
                    <li>Активний діяч української культури, дбав про відродження духовності та традицій українського народу.</li>
                    <li>Очолював обласне літоб'єднання ім. М. Гайдабури (1992-2016), був головою Запорізької обласної організації НСПУ (1998-2016), редактором журналу «Хортиця» (з 2001 року).</li>
                </ul>
                {/* <br/> */}
                <h4>Основні теми творчості:</h4>
              
                <ul>
                    <li>Любов до рідного краю, до людей, до природи, до жінки.</li>
                    <li>Єдність людини і природи.</li>
                    <li>Вічні цінності: кохання, молодість, вірність.</li>
                    <li>Історичні теми, козацтво.</li>
                    <li>Духовність українського народу.</li>
                </ul>
                <h4>Біографія</h4>
                Григорій Іванович ЛЮТИЙ  — український поет, член Національної спілки письменників України.
                Народився 7 лютого 1949 року в місті Гуляйполі Запорізької області в сім'ї вчителів і хліборобів...
                {/* <h4>Внесок у українську культуру:</h4>
                Григорій Лютий зробив значний внесок у розвиток української поезії. Його твори відзначаються щирістю, емоційною силою, глибиною думки. Він був активним діячем української культури, дбав про відродження духовності та традицій українського народу. */}


                </p>
                <br/>
                <button onClick={() => {navigate('/biography')}}>Детальніше про біографію</button>
                </div>

                
    
                {/* <section className='second-section'>
                <h1 className='title'>Біографія</h1>
                <div className='second-section__content'>
                    <div className='second-section__left-block'>
                        <p> 
                            {text1}
                        </p>
                        <button onClick={() => {navigate('/biography')}}>Детальніше про біографію</button>                
                    </div>
                    <div className='second-section__right-block'>
                        <img src={img3}/>
                    </div>
                </div>
                </section> */}

                <div className='fourth-section'>
            {store.isMobile && <div className='fourth-section__left-block'>
                    
                    </div>}
            <div className='fourth-section__right-block'>
                    <div className='fourth-section__content'>
                        <div className='fourth-section__content-title'>
                            <div>
                            <h1>Мама Марія</h1>
                            <h3>(Роман у віршах)</h3>
                            </div>
                        </div>
                        
                        <br/>
                        <p> 
                            Мама Марія - головна праця Григорія Лютого, перший у Запорізькому краї роман у віршах, який він писав 8 років. Твір переносить читача у серце українського фільклору та міфології. Роман є поєднанням історичної фантастики та магічного реалізму, де межі між реальністю та фантазією стираються, а духи минулого змішуються з сьогоденням.
                            <br/><br/>
                            Роман розповідає історію юної сироти Марійки, яка володіє чудовим співочим голосом та неймовірним даром бачити духів і спілкуватися з ними. Одного разу вона зустрічає таємничу стару жінку, яка впізнає Марійчин дар і розповідає, що їй призначена особлива доля...
                        
                        </p>
                        <br/>
                        <button className='fourth-section__button' onClick={() => {navigate('/mama-maria')}}>Читати роман</button>        
                    </div>
                </div>
                {!store.isMobile && <div className='fourth-section__left-block'>
                    
                    </div>}
                
                </div>
            </div>            
        </div>


    );
};

export default observer(Main);