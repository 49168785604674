import { useEffect, useState } from 'react';
import '../styles/menu.scss'
import { NavLink, useLocation, useParams } from 'react-router-dom';
import menu_icon from '../images/menu.png'
import cross from '../images/cross.png'
import Footer from './Footer';
import store from '../store/store';
import { observer, useObserver } from 'mobx-react-lite';


const Menu = ({changeable = false, children}) => {
    const [activeItem, setActiveItem] = useState(null); // State to keep track of active item index
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation();
    // Function to handle click on menu items

    const handleItemClick = (index) => {
        setActiveItem(index); // Update active item index
    };

    useEffect(() => {
        if(!location.pathname) return
        [{title: 'Головна', link: '/'}, {title: 'Вірші', link: '/poems'}, {title: 'Біографія', link: '/biography'}, {title: 'Мама Марія', link: '/mama-maria'}].forEach((item, index) => {
            if(item.link == location.pathname){
                setActiveItem(item.id);
            }
        })
        setIsOpen(false)
        console.log(activeItem)
    }, [location])

    
    
    useEffect(() => {
        handleWindowResize()
        window.addEventListener('resize', handleWindowResize);
    }, [])

    const handleWindowResize = () => {
        if (window.innerWidth <= 769 && !store.isMobile) {
            store.setIsMobile(true)
        }if (window.innerWidth >= 769 && store.isMobile) {
            store.setIsMobile(false)
        }
    }


    var headerTransformed = true;
            
    function parallaxScroll() {
        const header = document.querySelector('.menu');
        console.log('aaaa')
        if(!header) return
        
        if(changeable){
            console.log('bbb')
            if (window.scrollY > 1) {
                header.style.width = '100vw';
                header.style.left = '0';
                header.style.top = '0';
                
                headerTransformed = true;
            }
            if (headerTransformed === true && window.scrollY < 1) {
                header.style.width = 'calc(100vw - 100px)';
                header.style.left = '50px';
                header.style.top = '25px';

                headerTransformed = false;
            }
        }else{
            header.style.width = '100vw';
            header.style.left = '0';
            header.style.top = '0';
            
            headerTransformed = true;
        }
    }


    const resize = () => {
        
        if(!store.isMobile){ 
            parallaxScroll()
            
            window.addEventListener('scroll', parallaxScroll);
                
            
            return () => {
                window.removeEventListener('scroll', parallaxScroll);
            };
        }else{
            window.removeEventListener('scroll', parallaxScroll);
            const header = document.querySelector('.menu');
            if(!header) return
            header.style.width = '100vw';
            header.style.left = '0';
            header.style.top = '0';
            header.style.boxShadow = '0px 0px 20px 0px rgba(0,0,0,0.15)';
            headerTransformed = true;
        }
    }

    useEffect(resize, [store.isMobile, location.pathname])

    return (
        <div className='menu-container'>
            <div className={isOpen ? `menu open` : `menu `}>
                <div className='menu__top-bar'>
                    <div>
                        <h1>Григорій Лютий</h1>
                        <h6>Письменник Запорізького краю</h6>
                    </div>
                    {!store.isMobile && 
                    <ul className='desktop-list-menu'>
                        {[{title: 'Головна', link: '/', id: 0}, {title: 'Біографія', link: '/biography', id: 1}, {title: 'Вірші', link: '/poems', id: 2}, {title: 'Мама Марія', link: '/mama-maria', id: 3}].map((item, index) => (
                            <li key={index} onClick={() => handleItemClick(item.id)}>
                                <NavLink className={activeItem === item.id ? 'active' : ''} to={item.link}>{item.title}</NavLink>
                            </li>
                        ))}
                    </ul>
                    }
                    {store.isMobile && <button onClick={() => {setIsOpen(prev => !prev)}}>{isOpen ? <img src={cross}/> : <img src={menu_icon}/>}</button>}
                </div>

                {store.isMobile && 
                    <div className='mobile-menu-list'>
                        <ul>
                        {isOpen && <>{[{title: 'Головна', link: '/', id: 0}, {title: 'Біографія', link: '/biography', id: 1}, {title: 'Вірші', link: '/poems', id: 2}, {title: 'Мама Марія', link: '/mama-maria', id: 3}].map((item, index) => (
                            <li key={index} onClick={() => handleItemClick(item.id)}>
                                <NavLink className={activeItem === item.id ? 'active' : ''} to={item.link}>{item.title}</NavLink>
                            </li>
                        ))}</>}
                        </ul>
                    </div>
                }
            </div>
            {children}
            <Footer/>
        </div>
        
        
    );
};

export default observer(Menu);