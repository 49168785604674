import React, { useState, useEffect } from 'react';
import '../styles/item.scss'
import { useNavigate } from 'react-router-dom';

// Create a context for the images directory
const imagesContext = require.context('../images', true, /\.(png|jpe?g|svg)$/);

const Item = ({ name, id }) => {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const importImage = async () => {
      try {
        // Dynamically import the image using the context
        const imageModule = await import(`../images/item${id}.jpg`);
        setImgSrc(imageModule.default);
      } catch (err) {
        console.error(`Error importing image: ${err}`);
      }
    };

    importImage();
  }, [id]);

  const navigate = useNavigate()
  return (
    <div className="item" onClick={() => navigate(`/poem/${id}`)}>
      {imgSrc && <img src={imgSrc} alt={name} />}
      <h2 className="item__content">{name}</h2>
      <div className="item__shadow"></div>
      <button>Читати</button>
    </div>
  );
};

export default Item;