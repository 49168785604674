
import { Routes, Route, Switch } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Poems from './pages/poems/Poems.jsx';
import Main from './pages/Main.jsx';
import MenuWrapper from './components/MenuWrapper.jsx';
import MamaMaria from './pages/mama-maria/MamaMaria.jsx';
import Poem from './components/Poem.jsx';
import Biography from './pages/Biography.jsx';
import store from './store/store.js';

store.loadPoems()

// Define your routing structure
function App() {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <MenuWrapper changeable={true}>
            <Main />
          </MenuWrapper>
        }
      />
      <Route
        path="/poems"
        element={
          <MenuWrapper changeable={true}>
            <Poems />
          </MenuWrapper>
        }
      />
      <Route
        path="/biography"
        element={
          <MenuWrapper>
            <Biography />
          </MenuWrapper>
        }
      />
      <Route
        path="/poem/:id"
        element={
          <MenuWrapper changeable={true}>
            <Poem />
          </MenuWrapper>
        }
      />
      <Route
        path="/mama-maria"
        element={
          <MenuWrapper>
            <MamaMaria />
          </MenuWrapper>
        }
      />
      <Route element={<></>} />
    </Routes>
  );
}

export default App;