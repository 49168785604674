import '../styles/item.scss'
import Item from './Item';

const ItemsContainer = ({items}) => {

    return (
        <div className='items-container'>
            {items.map(item => (
                <Item name={item.name} id={item.id}/>
            ))}
        </div>
    );
};

export default ItemsContainer;